@import '../styles/colors.scss';

.dropDownMenu {
    position: relative;
    button {
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        img {
            transition: all .15s ease-in-out;
        }
        &.active {
            img {
                transform: rotate(90deg);
                opacity: 1;
            }
        }
        &:hover {
            cursor: pointer;
        }
    }
    .options {
        display: none;
        position: absolute;
        z-index: 2;
        top: 100%;
        right: 0;
        background: #fff;
        border-radius: 10px;
        box-shadow: 0 5px 10px rgba(0,0,0,.25);
        list-style: none;
        padding: 0;
        margin: 0;
        text-align: left;
        overflow: hidden;
        min-width: 120px;
        &.active {
            display: block;
        }
        li {
            white-space: no-wrap;
            padding: 6px 12px;
            font-size: 14px;          
            display: flex;
            align-items: center;  
            &:hover {
                background: #f5f5f5;
                cursor: pointer;
            }
            .dd-icon {
                display: inline-block;
                height: 28px;
                width: 28px;
                margin-right: 6px;
                background: #000;                
                mask-size: contain;
                mask-position: center;
                &.delete {
                    mask-image: url('../assets/img/delete.svg'); 
                    background: $error;                
                }
                &.edit {
                    mask-image: url('../assets/img/edit.svg');                    
                }
            }
        }
    }
}