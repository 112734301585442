.companyList {
    .companyListItem {            
        padding: 20px;
        margin-bottom: 10px;
        display: flex;
        text-decoration: none;
        font-weight: bold;
        color: #777;
        border-radius: 10px;
        box-shadow: 0 3px 4px rgb(0 0 0 / 5%);
        transition: all .2s ease-in-out;
        &:hover {
            color: #000;
            box-shadow: 0 6px 10px rgb(0 0 0 / 15%);
        }
    }
}
