.Payment {
    .payment-grid {
        .subtype {
            display: flex;
            align-items: center;
            img {
                margin-bottom: 1.5rem;
                margin-right: 10px;
                width: 86px;
                height: auto;
            }
        }
        .col {
            border: 1px solid #eee;
            padding: 20px;
            box-sizing: border-box;
            border-radius: 20px;
        }
    }
}