@media (max-width: 1024px) {
    .grid {
        .col-12 { grid-column: span 12; }
        .col-9 { grid-column: span 12; }
        .col-8 { grid-column: span 12; }
        .col-7 { grid-column: span 7; }
        .col-6 { grid-column: span 6; }
        .col-5 { grid-column: span 5; }
        .col-4 { grid-column: span 6; }
        .col-3 { grid-column: span 3; }
        .col-2 { grid-column: span 2; }
        .col-1 { grid-column: span 1; }
    }

    .padding-hero {
        padding-top: 120px;
        padding-bottom: 60px;
      }

    .padding-l {
        padding-top: 60px;
        padding-bottom: 60px;
      }

    .container {
        width: 94%;
    }

    .grid.gap-40 {
        grid-gap: 20px;
    }
}

@media (max-width: 860px) {
    .grid {
        .col-12 { grid-column: span 12; }
        .col-9 { grid-column: span 12; }
        .col-8 { grid-column: span 12; }
        .col-7 { grid-column: span 12; }
        .col-6 { grid-column: span 12; }
        .col-5 { grid-column: span 6; }
        .col-4 { grid-column: span 12; }
        .col-3 { grid-column: span 6; }        
    }   
}

@media (max-width: 560px) {
    .grid {
        .col-3 {
            grid-column: span 12;
        }
    }
}