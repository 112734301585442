@import "styles/colors.scss";

body {
  font-family: "Poppins", sans-serif;
  margin: 0;
  padding: 0;
  color: $fontColor;
  background-color: $bgGrey;
}

.KnegDashboard {
  display: flex;
  .content {
    width: 100%;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    .content-header {
      padding: 0 20px;
      box-sizing: border-box;
    }
    .content-body {
      flex-grow: 1;
      display: flex;
      flex-flow: column;
      background-color: #fff;
      border-radius: 20px;
      width: 100%;
      height: auto;
      padding: 20px;
      box-sizing: border-box;
      box-shadow: 0 6px 10px rgba(0, 0, 0, 0.05);
    }
    .content-body-footer {
      background-color: #fff;
      border-top: 1px solid #eee;
      padding: 20px;
      padding-bottom: 0;
      margin-left: -20px;
      margin-right: -20px;
      box-sizing: border-box;
    }
  }
  .DefaultTable {
    text-align: left;
    border-collapse: collapse;
    width: 100%;    
    tr {
      border-bottom: 1px solid #eee;
      &.pendingInvitation {
        color: #888;
      }            
    }    
    td,
    th {
      padding: 4px 8px;      
    }
    td {
      font-size: 14px;
    }
    td.iconCol {
      width: 40px;      
      img {
        opacity: 0.5;
        border-radius: 50%;
        &:hover {
          opacity: 1;
          background: #eee;          
          cursor: pointer;
        }
      }
    }
  }
}

a {
  color: #000;
}

.btn {
  border: none;
  padding: 10px 20px;
  border-radius: 10px;
  font-size: 16px;
  vertical-align: middle;
  font-weight: 600;
  display: inline-block;
  text-decoration: none;
  color: #333;
  transition: all 0.2s ease-in-out;
  &.big {
    padding: 16px 20px;
    &.icon-btn {
      padding: 8px 20px;
    }
  }
  &:hover {
    cursor: pointer;
    background-color: #eee;
  }
  &.icon-btn {
    padding: 2px 20px;
    img {
      max-width: 40px;
      margin-left: -14px;
      vertical-align: middle;
    }
  }
  &.disabled {
    pointer-events: none;
  }
  &.loading {
    background-color: #eee;
    padding-left: 40px;
    position: relative;
    pointer-events: none;
    color: rgba(0, 0, 0, 0.5);
    &.primary {
      background-color: #eee;
    }
    &.icon-btn {
      padding-left: 20px;
      img {
        opacity: 0;
      }
    }
    &:before {
      content: "";
      position: absolute;
      left: 2px;
      top: 2px;
      width: 40px;
      height: 40px;
      background: url("./assets/img/spinner-icon.svg") no-repeat;
      background-size: contain;
      background-position: center;
      animation-name: SpinnerLoading;
      animation-timing-function: linear;
      animation-duration: 2s;
      animation-iteration-count: infinite;
      opacity: 0.5;
    }
    .btn-loading-icon {
      display: inline-block;
      width: 40px;
      height: 40px;
      margin-left: -14px;
      vertical-align: middle;
      &:after {
        content: "";
        width: 100%;
        height: 100%;
        display: block;
        background: url("./assets/img/spinner-icon.svg") no-repeat;
        background-size: contain;
        background-position: center;
        animation-name: SpinnerLoading;
        animation-timing-function: linear;
        animation-duration: 2s;
        animation-iteration-count: infinite;
      }
    }
  }
  &.black {
    background: #000;
    color: $brandYellow;
    &:hover {
      color: #f6f413;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
    }
  }
  &.outline {
    box-shadow: inset 0 0 0 2px #333;
    &:hover {
      box-shadow: inset 0 0 0 2px #000, 0 3px 10px rgba(0, 0, 0, 0.2);
      color: #000;
      background-color: transparent;
    }
  }
  &.outline-white {
    box-shadow: inset 0 0 0 2px #e7e5e5;
    color: #e7e5e5;
    &:hover {
      box-shadow: inset 0 0 0 2px #fff, 0 3px 10px rgba(255, 255, 255, 0.2);
      color: #fff;
    }
  }
  &.primary {
    background: $brandYellow;
    &:hover {
      background: #f6f413;
      box-shadow: 0 8px 14px rgba(246, 198, 19, 0.4);
    }
    &.primary-glow {
      box-shadow: 0 5px 20px rgba(246, 198, 19, 0.75);
    }
  }
  &.remove {
    background: $error;
    color: #fff;
  }
  &.special {
    background: rgb(229, 165, 55);
    background: linear-gradient(45deg, rgba(191, 139, 50, 1) 0%, rgba(255, 234, 92, 1) 100%);
    background-size: 150%;
    background-position: right;
    &:hover {
      box-shadow: 0 8px 14px rgba(246, 198, 19, 0.4);
      background-position: 0 20%;
    }
  }
}

p {
  font-size: clamp(12.46px, 3.5vw, 18.5px);
  line-height: 1.6;
  margin-bottom: 1.5rem;
  margin-top: 0;
  &.info-text {
    margin: 10px 0;
    padding: 10px;
    padding-left: 40px;
    border-radius: 10px;
    box-sizing: border-box;
    background: $bgGrey url("./assets/img/info.svg") no-repeat;
    background-size: 32px;
    background-position: 5px center;
    font-size: clamp(12.46px, 3.5vw, 13.5px);
  }
}

.center-text {
  text-align: center;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  text-rendering: optimizelegibility;
  margin-top: 0;
}

h1 {
  font-size: clamp(2.3rem, 6vw, 3.95rem);
  line-height: 1.1;
  margin-bottom: 1.875rem;
}

h2 {
  font-size: clamp(2rem, 6vw, 2.75rem);
  line-height: 1.2;
  margin-bottom: 1.875rem;
}

h3 {
  font-size: clamp(1.55rem, 5vw, 2.3rem);
  line-height: 1.2;
  margin-bottom: 1.875rem;
}

h4 {
  font-size: clamp(1.4rem, 4.55vw, 1.875rem);
  line-height: 1.3;
  margin-bottom: 0.9rem;
}

h5 {
  font-size: clamp(1.25rem, 4.3vw, 1.5rem);
  line-height: 1.3;
  margin-bottom: 0.625rem;
}

h6 {
  font-size: clamp(1.15rem, 4.3vw, 1.3rem);
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 0.625rem;
}

.block-wrap {
  display: block;
  box-sizing: border-box;
  position: relative;
}

.container,
.nav {
  max-width: 1200px;
  width: 86%;
  box-sizing: border-box;
  margin: 0 auto;
  display: block;
}

.grid {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(12, minmax(0, 1fr));
  box-sizing: border-box;
  &.gap-40 {
    grid-gap: 40px;
  }
  &.gap-20 {
    grid-gap: 20px;
  }
  &.v-align {
    align-items: center;
  }
}

.col {
  box-sizing: border-box;
  width: 100%;
  position: relative;
  img {
    max-width: 100%;
  }
}

.col-12 {
  grid-column: span 12;
}
.col-9 {
  grid-column: span 9;
}
.col-8 {
  grid-column: span 8;
}
.col-7 {
  grid-column: span 7;
}
.col-6 {
  grid-column: span 6;
}
.col-5 {
  grid-column: span 5;
}
.col-4 {
  grid-column: span 4;
}
.col-3 {
  grid-column: span 3;
}
.col-2 {
  grid-column: span 2;
}
.col-1 {
  grid-column: span 1;
}

.padding-xl {
  padding-top: 160px;
  padding-bottom: 160px;
}

.padding-hero {
  padding-top: 180px;
  padding-bottom: 120px;
}

.padding-l {
  padding-top: 120px;
  padding-bottom: 120px;
}

.padding-m {
  padding-top: 80px;
  padding-bottom: 80px;
}

.padding-s {
  padding-top: 40px;
  padding-bottom: 40px;
}

.loading-frame {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  color: rgba(0, 0, 0, 0.1);
  .loader {
    width: 50px;
    height: 50px;
    mask: url("./assets/img/kneg-icon.svg") no-repeat;
    mask-position: center;
    mask-size: contain;
    background: linear-gradient(245deg, #f5f5f5, #000, $brandYellow);
    background-size: 400% 400%;
    -webkit-animation: Loading 1s ease-in-out infinite;
    -moz-animation: Loading 1s ease-in-out infinite;
    animation: Loading 1s ease-in-out infinite;
  }
}

@-webkit-keyframes Loading {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes Loading {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes Loading {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes SpinnerLoading {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@import "responsive.scss";
