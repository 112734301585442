.UserManagementList {
  text-align: left;
  border-collapse: collapse;
  width: 100%;
  td:first-child {
    width: 60px;
  }
  tr {
    border-bottom: 1px solid #eee;
    &.pendingInvitation {
      color: #888;
    }
  }
  td,
  th {
    padding: 10px 5px;
  }
  td.iconCol {
    width: 40px;
    img {
      opacity: 0.75;
      &:hover {
        opacity: 1;
        background: #eee;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }

  .status {
    display: inline-block;
    &.active {
      width: 12px;
      height: 12px;
      margin-right: 4px;
      margin-left: 6px;
      border-radius: 50%;
      background-color: #8dc63f;
    }
    &.pending {
      width: 24px;
      height: 24px;
      background: url("../assets/img/mail.svg") no-repeat;
      background-size: contain;
      background-position: center;
      opacity: 0.6;
      vertical-align: middle;
    }
    &.accepted {
      width: 24px;
      height: 24px;
      background: url("../assets/img/check-black.svg") no-repeat;
      background-size: contain;
      background-position: center;
      opacity: 0.6;
      vertical-align: middle;
    }
  }
}
