@import '../styles/colors.scss';

.user-thumbnail {    
    position: relative;     
    display: inline-block;
    .profile-picture, .profile-initials {
        width: 52px;
        height: 52px;
        background-color: #000;
        border-radius: 50%;
        overflow: hidden;
        background-size: contain;        
        position: relative;              
        img {
            width: 100%;
            height: auto;
        }
        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            color: $brandYellow;
            font-size: 18px;
            font-weight: bold;
            &.noname {
                width: 40px;
                height: 40px;
                background-color: $brandYellow;
                mask-image: url('../assets/img/user.svg');
                mask-size: contain;
                mask-position: center;                
            }
        }
    }
}