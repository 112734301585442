@import "../../styles/colors.scss";

select.SelectInput {
  font-family: "Poppins", sans-serif;
  position: relative;
  z-index: 1;
  padding: 16px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  border: 2px solid #999;
  background: rgba(255, 255, 255, 0.8);
  min-width: 280px;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  &:hover {
    border-color: #555;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05);
  }
  &:focus {
    border-color: $brandYellow;
    box-shadow: 0 10px 10px rgba(246, 198, 19, 0.2);
    outline: none;
  } 
}

.SelectInputLabel {
  display: block;
  margin-bottom: 10px;
  &:hover,
  &:focus {
    color: #000;
  }
  .optionalField {
    color: #777;
    font-size: 14px;
    margin-left: 10px;
  }
}