@import '../styles/colors.scss';

.MainNav {
    width: 240px;
    padding: 16px;
    flex-shrink: 0;
    box-sizing: border-box;
    height: 100vh;
    background: $brandYellow;
    display: flex;
    flex-flow: column;
    .nav-logo {
        display: block;
        max-width: 140px;
        width: 100%;
        margin: 30px auto;
    }
    .nav-logo-mobile {
        display: none;        
        max-width: 32px;
        width: 100%;
        margin: 30px auto;
    }
    .navlist {
        list-style: none;
        padding: 0;
        margin: 0;
        li {
            a {
                display: flex;
                align-items: center;
                padding: 0 6px;
                border-radius: 10px;
                font-weight: 600;
                margin-bottom: 6px;
                color: #333;
                text-decoration: none;
                &:hover, &.active {
                    color: $brandYellow;
                    background-color: #000;
                    .menu-icon {
                        background-color: $brandYellow;
                    }
                }
                &.disabled {
                    opacity: 0.5;
                    pointer-events: none;
                }
            }            
        }
        .menu-icon {
            width: 40px;
            height: 40px;
            background-color: #000;
            display: inline-block;
            margin-right: 14px;       
            flex-shrink: 0;     
            &.companies {
                mask: url(../assets/img/company.svg) no-repeat;
                mask-size: contain;
            }
            &.users {
                mask: url(../assets/img/user.svg) no-repeat;
                mask-size: contain;
            }            
            &.tasks {
                mask: url(../assets/img/checkbox-checked.svg) no-repeat;
                mask-size: contain;
            }
            &.offer {
                mask: url(../assets/img/task.svg) no-repeat;
                mask-size: contain;
            }
            &.clients {
                mask: url(../assets/img/star.svg) no-repeat;
                mask-size: contain;
            }
            &.templates {
                mask: url(../assets/img/mark-all-done.svg) no-repeat;
                mask-size: contain;
            }
            &.settings {
                mask: url(../assets/img/settings.svg) no-repeat;
                mask-size: contain;
            }
            &.payment {
                mask: url(../assets/img/wallet.svg) no-repeat;
                mask-size: contain;
            }
        }
    }
    .signed-user {
        margin-top: auto;
        text-align: center;
        background-color: #000;
        padding: 20px;
        padding-top: 30px;
        border-radius: 20px;
        a {
            color: $brandYellow;
            text-decoration: none;
            &:hover {
                color: #fff;
                .user-thumbnail:after {
                    border-color: #fff;
                }
            }
        }        
        .btn {
            display: block;          
        }        
        b {
            display: block;
            margin: 10px 0;
        }
        .user-thumbnail {
            &:after {
                content: "";
                width: 60px;
                height: 60px;
                position: absolute;
                top: -8px;
                left: 50%;
                transform: translateX(-50%);
                border-radius: 50%;
                border: 3px solid $brandYellow;
            }
        }        
    }

}

@media (max-width: 960px) {
    .MainNav {
        width: 68px;
        padding: 10px;
        .user-profile b {
            display: none;
        }
        .nav-logo {
            display: none;
        }
        .nav-logo-mobile {
            display: block;
        }
        .btn {
            padding: 4px;
            position: relative;
            box-shadow: none;
            .btn-txt {
                opacity: 0;
                position: absolute;
                left: 100%;
                margin-left: 0px;
                pointer-events: none;
                font-size: 13px;
                background: #000;
                padding: 2px 8px;
                border-radius: 20px;
                color: #fff;     
                transition: all .2s ease-in-out;
                white-space: nowrap;
            }
            &:hover {
                span {
                    opacity: 1;
                    margin-left: 6px;
                }
            }   
        }
        
        .navlist {
            li a {
                padding: 4px;
                position: relative;
                span {                    
                    opacity: 0;
                    position: absolute;
                    left: 100%;
                    margin-left: 0px;
                    pointer-events: none;
                    font-size: 13px;
                    background: #000;
                    padding: 2px 8px;
                    border-radius: 20px;
                    white-space: nowrap;
                    color: #fff;     
                    transition: all .2s ease-in-out;   
                    z-index: 2;            
                }
                .menu-icon {
                    margin: 0;
                }
                &:hover {
                    span {
                        opacity: 1;
                        margin-left: 6px;
                    }
                }
            }
        }
        .signed-user {
            padding: 20px 10px;
            margin-left: -10px;
            margin-right: -10px;
            margin-bottom: -10px;
            border-radius: 0;
            .btn.icon-btn {
                margin-top: 20px;
                img {
                    margin: 0;
                }
            } 
            .user-thumbnail:after {
                display: none;
            }            
            b {
                display: none;
            }
            .profile-picture {
                width: 48px;
                height: 48px;
            }
        }
    }
}