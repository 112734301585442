@import '../styles/colors.scss';

.SnackBar {
    position: fixed;
    z-index: 501;
    top: 20px;
    right: 20px;
    width: 100%;
    max-width: 400px;
    background: #fff;        
    padding: 10px 0;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    align-items: center;   
    overflow: hidden;        
    animation: show-and-hide; 
    animation-duration: 3s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: forwards;
    box-shadow: 0 10px 20px rgba(0,0,0,.15);
    .snackBarIcon {
        display: inline-block;
        flex-shrink: 0;
        width: 40px;
        height: 40px;
        mask: url('../assets/img/info.svg');    
        mask-size: contain;
        background-color: #999;
        &.warning {
            background-color: $brandYellow;
            mask: url('../assets/img/warning.svg');
        }
        &.success {
            mask: url('../assets/img/checkbox-checked.svg');
            background-color: $success;
        }
        &.error {
            mask: url('../assets/img/error.svg');
            background-color: $error;
        }
    }
    .snackBarText {
        flex-grow: 2;
        font-size: 14px;
    }
    .close-snackbar-btn {
        margin: 0 5px;
        flex-shrink: 0;
        display: inline-block;
        width: 32px;
        height: 32px;
        background: url('../assets/img/close.svg') no-repeat;
        background-size: contain;
        background-position: center;
        opacity: 0.6;
        border-radius: 50%;
        transition: all .2s ease-in-out;
        &:hover {
            cursor: pointer;
            opacity: 1;
            background-color: #eee;                    
        }
    }
    .snackBarDurationProgress {        
        background: #eee;
        right: 0;
        position: absolute;
        bottom: 0;
        left: 0;        
        height: 4px;
    }
    .progress {        
        position: absolute;
        bottom: 0;
        left: 0;        
        height: 4px;
        background: #999;
        width: 50%;
        animation: snackbar;
        animation-duration: 3s;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        &.warning {
            background-color: $brandYellow;
        }
        &.success {
            background-color: $success;
        }
        &.error {
            background-color: $error;
        }
    }        
}

@keyframes snackbar {
    from { width: 0;}
    to { width: 100%;}
}

@keyframes slide-up {
    from {
      opacity: 0;
      transform: translateY(-3rem);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  @keyframes show-and-hide {
    0% {
        opacity: 0;
        transform: translateY(-3rem);
    }
    5% {
        opacity: 1;
        transform: translateY(0);
    }
    95% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 0;
        transform: translateY(-3rem);
    }    
  }