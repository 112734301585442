@import "../styles/colors.scss";

.modal-backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 500;
}
.modal-wrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 700;
    width: 100%;
    max-width: 500px;
    outline: 0;
    &.fullscreen {
        max-width: 80%;
        .modal-body {
            min-height: 60vh;
        }
    }
    .modal {
        z-index: 100;        
        background: white;
        border-radius: 20px;
        position: relative;
        margin: auto;
        box-shadow: 0 10px 40px rgba(0,0,0,.25);
        .modal-header {
            padding: 16px;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #eee;
            h5 {
                margin: 0;                
            }                                    
            .close-modal-btn {
                display: inline-block;
                width: 32px;
                height: 32px;
                background: url('../assets/img/close.svg') no-repeat;
                background-size: contain;
                background-position: center;
                opacity: 0.6;
                border-radius: 50%;
                transition: all .2s ease-in-out;
                &:hover {
                    cursor: pointer;
                    opacity: 1;
                    background-color: #eee;                    
                }
            }
        }
        .modal-body {
            padding: 16px;
            position: relative;
            .error-msg {
                font-size: 13px;                
                min-height: 28px;
                margin: 0;
                margin-bottom: 4px;
                color: $error;
            }
            .small-add-button {
                border: none;
                padding: 0 10px;
                font-size: 16px;
                vertical-align: middle;
                font-weight: 600;
                display: inline-block;
                text-decoration: none;
                color: $brandYellow;
                transition: all 0.2s ease-in-out;
                &:hover {
                    background-color: #eee;
                    cursor: pointer;
                }
            }
            .addSubBtn {
                display: flex;
                align-items: center;
                opacity: 0.7;
                &:hover {
                    opacity: 1;
                    cursor: pointer;
                }
            }

        }
        .modal-footer {
            text-align: right;
            padding: 10px 16px;
            border-top: 1px solid #eee;
            .btn {
                margin-left: 10px;
            }
        }
    }
}