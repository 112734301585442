.mainContent {
    width: 100%;
    max-height: 100vh;
    padding: 30px;
    box-sizing: border-box;
    display: flex;
    flex-flow: column;
    .mainContentHeader {
      padding: 0 20px;
      box-sizing: border-box;
      display: flex;
    }
    
    .mainContentCard {
      flex-grow: 1;
      display: flex;
      flex-flow: column;
      background-color: #fff;
      border-radius: 20px;
      width: 100%;
      height: auto;    
      box-sizing: border-box;
      box-shadow: 0 6px 10px rgba(0,0,0,.05);
    }
  
    .cardHeader {
      padding: 20px;
      box-sizing: border-box;
      border-bottom: 1px solid #eee;
      width: 100%;
    }
    .cardBody {
      padding: 20px;
      box-sizing: border-box;
      flex-grow: 2;
      width: 100%;
      height: 0;
      overflow-y: auto;    
    }
    .cardFooter {
      padding: 20px;
      border-top: 1px solid #eee;
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
    }
  }