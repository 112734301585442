.TemplateListPage {
    .templateListItem {
        display: flex;
        padding-right: 12px;
        align-items: center;
        border-bottom: 1px solid #eee;
        &:last-child {
            border-bottom: none;
        }
        &:hover {
            background-color: #eee;
        }
        a {
            display: inline-block;
            padding: 20px;
            flex-grow: 2;
            font-weight: 600;
            text-decoration: none;
            color: #555;
            &:hover {
                color: #000;
            }
        }
    }
}

.row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.small-input .textfield {
    flex: 1;
    margin-right: 10px;
}

.small-input .remove-btn { 
    width: 40px;
    img {
        opacity: 0.5;
        border-radius: 50%;
        &:hover {
        opacity: 1;
        background: #eee;          
        cursor: pointer;
        }
    }
}