
//Base styling for entire table
.MuiDataGrid-root.MuiDataGrid-root--densityStandard {
    border: none;
    font-family: 'Poppins', sans-serif;
}

//Table header font weight
.MuiDataGrid-columnHeaders .MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-columnHeaderTitleContainerContent .MuiDataGrid-columnHeaderTitle {
    font-weight: 700;
}

//Selected row bg color
.MuiDataGrid-row.Mui-selected {
    background: rgb(246 198 18 / 10%) !important;
}

//Set color for checkbox
.Mui-checked.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiDataGrid-checkboxInput {
    color: #f6c612;
}

//Hiding small line next to table headers
.MuiDataGrid-root .MuiDataGrid-iconSeparator {
    display: none;
}

//Hiding pagination
.MuiDataGrid-root .MuiDataGrid-footerContainer {
    display: none;
}