.RedeemInvitationPage {
    display: flex;
    min-height: 100vh;
    .inviteLogo {
        width: 100%;
        max-width: 160px;
        margin-bottom: 2.875rem;
    }
    .inviteTagline {
        font-weight: bold;
        margin: 0;
    }
    .inviteInfoList {
        list-style: none;
        padding: 0;
        margin: 0;
        margin-bottom: 2.875rem;
        li {            
            font-weight: 600;
            img {
                vertical-align: middle;
                margin-right: 6px;           
            }
        }
    }
    .inviteFormContainer {
        background-color: #fff;
        display: flex;        
        width: 50%;
        min-height: 100vh;
        max-height: 100vh;        
        height: 100%;
        overflow-y: auto;
        flex-shrink: 0;
        justify-content: center;
        align-items: center;
        .inviteFormBlock {
            width: 100%;
            max-width: 80%;
            padding: 20px;
            box-sizing: border-box;
            display: block;
        }
    }
    .inviteForm {
        max-width: 360px;
        .btn {
            display: block;
            text-align: center;
            &.loading {
                &::before {
                    display: none;
                }
            }
        }
    }
    .inviteBackdrop {
        width: 50%;
        min-height: 100vh;
        height: 100%;
        flex-shrink: 0;
        display: block;
        background: url('../../assets/img/sign-in-bg.png') no-repeat;
        background-size: cover;
        background-position: center;
    }
}

@media (max-width: 860px) {
    .RedeemInvitationPage {
        background: url('../../assets/img/sign-in-bg.png') no-repeat;
        background-size: cover;
        background-position: center;
        justify-content: center;
        padding: 0;
        box-sizing: border-box;
        display: block;
        background-attachment: fixed;
    
        .inviteFormContainer {            
            display: block;
            padding: 0;
            background: none;
            width: 100%;
            min-height: auto;
            max-height: initial;            
            overflow-y: initial;
            padding: 10px;
            box-sizing: border-box;
            .inviteFormBlock {
                display: block;
                border-radius: 20px;
                background: #fff;
                padding: 40px 14px;                
                max-width: 100%;
            }
        }
        .inviteBackdrop {
            display: none;
        }
    }   
}