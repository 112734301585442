@import "../styles/colors.scss";

.Login {
    background: $brandYellow;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 100%;
        padding: 40px;
        box-sizing: border-box;
        opacity: 0.9;
    }

    .login-wrap {
        width: 100%;
        max-width: 360px;
        padding: 40px;
        box-sizing: border-box;
        border-radius: 20px;
        box-shadow: 0 20px 80px rgba(0,0,0,.1);     
    }

    .error-msg {
        display: block;
        text-align: center;
        font-size: 14px;
        font-weight: 600;
        min-height: 28px;
        margin-top: 4px;
    }

    input[type="email"], input[type="password"] {
        border: none;
        font-size: 16px;
        background: none;
        border: 3px solid #f6aa12;
        padding: 16px;
        border-radius: 10px;
        display: block;
        width: 100%;
        box-sizing: border-box;        
        font-weight: 600;
        transition: all .2s ease-in-out;
        &:-internal-autofill-selected {
            background-color: red;
        }        
        &:focus, &:hover {
            background: none;
            border-color:#000;
            box-shadow: 0 10px 20px rgba(0,0,0,.2);
        }
    }
    button[type="submit"] {
        border: none;
        padding: 18px 10px;
        font-size: 18px;
        font-weight: 800;
        border-radius: 10px;
        background: #333;
        color: $brandYellow;
        display: block;
        width: 100%;
        transition: all .2s ease-in-out;
        &:focus, &:hover {
            background: #000;
            box-shadow: 0 10px 20px rgba(0,0,0,.3);
        }
        .btn-loading-icon {
            display: none;
        }
        &.loading {
            background-color: #555;            
            &:before {
                filter: invert(1);
                top: 8px;
                left: 10px
            }
        }        
    }

    input:focus, textarea:focus, select:focus, button:focus{
        outline: none;
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #333;
        opacity: 1; /* Firefox */
      }
      
      :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #333;
      }
      
      ::-ms-input-placeholder { /* Microsoft Edge */
        color: #333;
      }

    @-webkit-keyframes autofill {
        0%,100% {
            color: #666;
            background: transparent;
        }
    }
    
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover,
    textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
    border: 3px solid #000;
    -webkit-text-fill-color: none;
    -webkit-box-shadow: 0 0 0px 1000px $brandYellow inset;
    transition: background-color 5000s ease-in-out 0s;
    }
}