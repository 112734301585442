.CompanyDetailPage {
  .mainContentHeader {
    a {
      color: #777;
      text-decoration: none;
      &:hover {
        color: #333;
        text-decoration: underline;
      }
    }
  }
}
