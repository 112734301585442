@import '../styles/colors.scss';

.Template {    
    .mainContentHeader {
        a {
          color: #777;
          text-decoration: none;
          &:hover {
            color: #333;
            text-decoration: underline;
          }
        }
      }
      .mainContent {
        
        .mainContentCard {
            background-color: inherit;
            box-shadow: none;
            h6 {
                margin-top: 20px;
                color: #777;
                text-transform: uppercase;
                font-size: 14px;
            }
          }
          .cardFooter {
            border: none;
            background-color: #fff;
            border-radius: 40px;
          }
      }        

      .taskListItem {
        background: #fff url('../assets/img/checkbox-checked.svg') no-repeat;
        background-position: 10px center;
        padding: 2px 6px;
        padding-left: 60px;
        margin-bottom: 6px;
        display: flex;
        align-items: center;        
        text-decoration: none;
        font-weight: 600;
        color: #777;
        border-radius: 10px;
        box-shadow: 0 3px 4px rgb(0 0 0 / 5%);
        transition: all .2s ease-in-out;
        > span {
            flex-grow: 2;
            display: flex;
            align-items: center;            
            .task-steps-counter {
                display: flex;
                align-items: center;
                font-size: 14px;
                margin-right: 10px;
                img {
                    opacity: 0.6;
                    height: 24px;
                    transition: all .2s ease-in-out;
                }
                &:first-child {
                    margin-left: auto;
                }
            }
        }
        .dropDownMenu {
            opacity: 0;
        }
        &:hover {
            .dropDownMenu {
                opacity: 1;
            }
            .task-steps-counter img {
                opacity: 1;
            }
            color: #000;
            box-shadow: 0 6px 10px rgb(0 0 0 / 15%);
        }
        cursor: pointer;
      }      

      .infoListItem {
        background: url('../assets/img/dot-information.svg') no-repeat;
        background-position: 10px center;
        padding: 2px 6px;
        padding-left: 60px;
        margin-bottom: 6px;
        display: flex;
        border-radius: 10px;
        align-items: center;        
        text-decoration: none;
        font-weight: 600;
        color: #777;
        transition: all .2s ease-in-out;
        > span {
            flex-grow: 2;
        }
        .dropDownMenu {
            opacity: 0;
        }
        &:hover {
            .dropDownMenu {
                opacity: 1;
            }
            color: #000;
            background-color: #eee;
        }
      }

    .mainContent {
        .cardFooter {
            justify-content: flex-start;            
            form {
                display: flex;
                align-items: center;
                width: 100%;
            }
            .btn {
                border-radius: 50px;
                padding: 12px 24px;
            }
            .taskTypes {
                > div {
                    padding: 10px 16px;
                    border-radius: 30px;
                    display: inline-block;
                    background-color: #eee;
                    color: #777;
                    font-weight: 600;
                    &:first-child {
                        margin-right: 5px;
                    }                    
                    &:hover {
                        cursor: pointer;
                        color: $brandYellow;
                        background-color: #555;
                    }
                    &.active {
                        color: $brandYellow;
                        background-color: #000;
                    }
                }
            }
            .taskTxt {
                flex-grow: 2;
                padding: 0 10px;
                box-sizing: border-box;
                .TextInputLabel {
                    margin: 0;
                    input[type="text"] {
                        border-radius: 100px;
                        padding: 10px 16px;
                    }
                }
            }
                
        }
    }    
}