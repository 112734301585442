@import "../../styles/colors.scss";

input.TextInput,
.small-input input.TextInput {
  font-family: "Poppins", sans-serif;
  position: relative;
  z-index: 1;
  padding: 16px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 500;
  border: 2px solid #999;
  background: rgba(255, 255, 255, 0.8);
  min-width: 280px;
  width: 100%;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
  &:hover {
    border-color: #555;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.05);
  }
  &:focus {
    border-color: $brandYellow;
    box-shadow: 0 10px 10px rgba(246, 198, 19, 0.2);
    outline: none;
  }
}

.small-input input.TextInput {
  padding: 8px;
  padding-left: 16px;
}

input[type="email"].TextInput {
  padding-left: 40px;
  background-image: url('../../assets/img/mail.svg');
  background-repeat: no-repeat;
  background-size: 32px;
  background-position: 6px center;
}

input[type="password"].TextInput {
  padding-left: 40px;
  background-image: url('../../assets/img/lock.svg');
  background-repeat: no-repeat;
  background-size: 32px;
  background-position: 6px center;
}

input[type="tel"].TextInput {
  padding-left: 40px;
  background-image: url('../../assets/img/phone.svg');
  background-repeat: no-repeat;
  background-size: 32px;
  background-position: 6px center;
}

input[type="search"].TextInput {
  padding-left: 40px;
  background-image: url('../../assets/img/search.svg');
  background-repeat: no-repeat;
  background-size: 32px;
  background-position: 6px center;
}

.TextInputLabel {
  display: block;
  margin-bottom: 10px;
  &:hover,
  &:focus {
    color: #000;
  }
  .optionalField {
    color: #777;
    font-size: 14px;
    margin-left: 10px;
  }
}