@import '../styles/colors.scss';

.UserDetailPage {    
    .profile-thumb {      
        flex-shrink: 1;  
        padding-right: 40px;
        position: relative;
            .profile-image {
                width: 160px;
                height: 160px;
                border: 4px solid $brandYellow;
                border-radius: 50%;
                overflow: hidden;
                background: #eee url('../assets/img/user.svg') no-repeat;
                background-size: contain;
                background-position: center;
            }     
            label {display: block;}       
    }
    .profile-txt {
        flex-grow: 2;
        width: 100%;
        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            li {
                img {
                    vertical-align: middle;
                    margin-right: 10px;
                }
            }
        }
    }    
    .profile-wrap {
        display: flex;
    }
}

.upload-btn, .delete-btn {
    width: 40px;
    height: 40px;
    display: inline-block;
    left: 124px;
    top: 124px;
    position: absolute;
    border-radius: 50%;    
    background-color: $error;
    transition: all .15s ease-in-out;
    &:after {
        content: "";
        width: 40px;
        height: 40px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        mask-image: url('../assets/img/delete.svg');
        mask-size: contain;
        mask-position: center;
        background-color: #fff;
    }
    &:hover {
        box-shadow: 0 5px 10px rgba(0,0,0,.25);
        cursor: pointer;        
    }
}

.upload-btn {    
    background-color: $brandYellow;
    &:after {
        mask-image: url(../assets/img/export.svg);
        background-color: #000;
    }
    input[type="file"] {
        display: none;
    }
}